import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import StarRating from "../../Components/Body/StarRating";
import LikeProducts from "./LikeProducts";
import RelatedProducts from "./RelatedProducts";
import axios from "axios";
import { placeholderProducts } from "../../CommonMethods";
import { useDispatch, useSelector } from "react-redux";
import { alterCart, deleteCart } from "../../store/cart/CartActions";
import MinusPlusContainer from "../../Components/Body/MinusPlusContainer";
import { Whatsapp } from "react-bootstrap-icons";
import { Helmet } from "react-helmet-async";

const ProductPage = () => {
  // const location = useLocation();
  // const urlParams = new URLSearchParams(location.search);
  // const name = urlParams.get("name");
  const dispatch = useDispatch();

  const { loading, shopping_cart } = useSelector((state) => state.cart);

  // let { id } = useParams();

  const { productname } = useParams();

  let id = productname.split("-").slice(-1);

  let { state } = useLocation();

  const [inCartItem, setCartItem] = useState(null);

  useEffect(() => {
    let r = (shopping_cart || []).find((x) => x.prod_id === parseInt(id));
    if (r) {
      setCartItem(r);
    }
  }, [id, shopping_cart]);

  const [product, setProduct] = useState(null);

  const [percentOff, setPercentOff] = useState(0);

  const [fetching, setFetching] = useState(true);

  async function fetchProdOnline() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/product/${id}/`)
      .catch((_) => {})
      .then((response) => {
        if (response && response.status === 200) {
          setProduct(response.data);
        }
      });
  }

  useEffect(() => {
    setFetching(true);
    if (state && state.item) {
      setProduct(state.item);
    } else {
      // let p = productsList.find((p) => p.id == id);
      if (parseInt(id)) {
        fetchProdOnline();
      }
    }
    setFetching(false);
  }, [state, id]);

  useEffect(() => {
    function calculatePercentOff() {
      let perc = 0;
      if (product) {
        if (product.price && product.regular_price) {
          perc =
            ((product.regular_price - product.price) / product.regular_price) *
            100;
        }
      }

      return perc;
    }
    setPercentOff(calculatePercentOff());
  }, [product]);

  function getCategories(paramList) {
    const elem = [];

    for (const i of paramList) {
      elem.push(
        <span key={i.id}>
          &gt; <Link to={`/${i.name}/`}> {i.name}</Link>
        </span>
      );
    }

    return elem;
  }

  const onChange = (e) => {
    if (parseInt(e.target.value)) {
      if (e.target.value > 0) {
        if (inCartItem && inCartItem.prod_id) {
          dispatch(
            alterCart({
              id: inCartItem.id,
              prod_id: inCartItem.prod_id,
              quantity: parseInt(e.target.value),
              meta_data: inCartItem.meta_data,
            })
          );
        }
      }
    }
  };

  const onAdd = () => {
    if (inCartItem && inCartItem.prod_id) {
      dispatch(
        alterCart({
          id: inCartItem.id,
          prod_id: inCartItem.prod_id,
          quantity: inCartItem.quantity + 1,
          meta_data: inCartItem.meta_data,
        })
      );
    }
  };

  const onMinus = () => {
    if (inCartItem && inCartItem.prod_id) {
      if (inCartItem.quantity > 1) {
        dispatch(
          alterCart({
            id: inCartItem.id,
            prod_id: inCartItem.prod_id,
            quantity: inCartItem.quantity - 1,
            meta_data: inCartItem.meta_data,
          })
        );
      } else {
        dispatch(
          deleteCart({ id: inCartItem.id, prod_id: inCartItem.prod_id })
        );
      }
    }
  };

  return (
    <div className="productDetails">
      <Helmet>
        <title>TCF Store | {productname}</title>
        <meta
          name="description"
          content={`Check out TCF Accessories' ${productname}.`}
        />
        <link rel="canonical" href={`/product/${productname}`} />
      </Helmet>
      <Container>
        <div className="followLinks">
          <Link to={"/"}>Home </Link>
          {product ? <>{getCategories(product.categories || [])}</> : <></>}
        </div>
        {fetching ? (
          <>
            <Row className="loading-product">
              <Col sm={4} className="loading-product-image">
                <img alt="" />
              </Col>
              <Col sm={6}>
                <div className="loading-product-content-1">
                  <img alt="" />
                </div>
                <div className="loading-product-content-2">
                  <img alt="" />
                </div>
                <hr />
                <div className="loading-product-content-3">
                  <img alt="" />
                </div>
                <div className="loading-product-content-4">
                  <img alt="" />
                </div>
                <Button
                  variant="secondary"
                  className="loading-product-btn"
                  disabled
                >
                  ADD TO CART
                </Button>
              </Col>
              <Col sm={12} className="p-box prod-spec-placeholder"></Col>
              <Col className="p-box prod-spec-placeholder">
                <Col sm={6}></Col>
                <Col sm={6}></Col>
              </Col>
            </Row>

            <div className="scrollProductsContainer">
              <Row className="pListProducts">{placeholderProducts(8)}</Row>
            </div>
          </>
        ) : product ? (
          <>
            <Row className="mainDetails">
              <Col sm={4}>
                <img
                  src={`${process.env.REACT_APP_API_URL}${
                    product.images[0]?.src ?? ""
                  }`}
                  alt={product.images[0]?.alt ?? ""}
                />
              </Col>
              <Col sm={6} className="pdetails">
                <p className="pName">{product.name}</p>
                <p>
                  Brand :{" "}
                  <Link to={`/catalog/?q=${product.brand}`}>
                    {product.brand.name || ""}
                  </Link>
                </p>
                <hr />
                <p className="pPrice">Ksh {product.price}</p>
                {percentOff > 0 ? (
                  <p>
                    <span className="pRegPrice"> {product.regular_price} </span>{" "}
                    <span className="pPercOff"> -{percentOff.toFixed(1)}%</span>
                  </p>
                ) : (
                  <></>
                )}
                <p
                  className="pStock"
                  style={{
                    color:
                      product.stock_status === "instock"
                        ? "green"
                        : "#aa1111ab",
                  }}
                >
                  {product.stock_status}
                </p>
                <div className="pRatings">
                  {product.rating_count >= 1 ? (
                    <StarRating value={product.average_rating} />
                  ) : (
                    <></>
                  )}
                </div>

                <Link
                  to={`https://wa.me/254715862727/?text=${encodeURIComponent(
                    "Hello, I would like to purchase \n\nProduct :" +
                      `${product.name}` +
                      "\nPrice : Ksh " +
                      `${product.price}` +
                      `\nURL : ${encodeURI(
                        `https://www.tcfstore.com/product/${product.name}-${product.id}`
                      )}` +
                      "/\n\nThank you."
                  )}`}
                  target="_blank"
                  className="product_whatsapp"
                >
                  WHATSAPP
                </Link>

                {!(shopping_cart || []).some(
                  (c) => c.prod_id === product.id
                ) ? (
                  <Button
                    variant="success"
                    className="p-details-add-cart"
                    onClick={() =>
                      dispatch(
                        alterCart({
                          id: null,
                          prod_id: product.id,
                          quantity: 1,
                          meta_data: [],
                        })
                      )
                    }
                    disabled={loading}
                  >
                    {loading ? "ADDING ...." : "ADD TO CART"}
                  </Button>
                ) : (
                  <MinusPlusContainer
                    val={inCartItem?.quantity || 0}
                    onChange={onChange}
                    onAdd={onAdd}
                    onMinus={onMinus}
                  />
                )}
              </Col>
            </Row>
            <Row className="p-box productDescription">
              <h5 className="pTitle"> Description</h5>
              <hr />
              <div>{product.description}</div>
            </Row>
            <Row className="p-box productFeatures">
              <h5 className="pTitle"> Features</h5>
              <hr />
              <Col sm={12}>
                <ul>
                  {product.features.split("\n").map((feat, index) => {
                    return <li key={index}>{feat}</li>;
                  })}
                </ul>
              </Col>
            </Row>
            <Row className="p-box productSpecifications">
              <h5 className="pTitle"> Specifications</h5>
              <hr />
              <Col sm={6}>
                <p>
                  <b>Specs</b>
                </p>
                <ul>
                  <li>
                    <b>Model</b> - {product.model}
                  </li>
                  <li>
                    <b>Weight</b> - {product.weight}
                  </li>
                  <li>
                    <b>Height</b> - {product.dimensions.height}
                  </li>
                  <li>
                    <b>Width</b> - {product.dimensions.width}
                  </li>
                  <li>
                    <b>Breadth</b> - {product.dimensions.length}
                  </li>
                </ul>
              </Col>
              <Col sm={6}>
                <p>
                  <b>What's In The Box</b>
                </p>
                <ul>
                  {product.whats_in_box.split("\n").map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              </Col>
            </Row>

            <RelatedProducts related_products={product.related_ids} />

            <LikeProducts product_categories={product.categories} />
          </>
        ) : (
          <center>No Item Found</center>
        )}
      </Container>
    </div>
  );
};

export default ProductPage;
