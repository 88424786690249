import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="TCF Accessories: High Quality Electronic Products."
        />
        <link rel="canonical" href="/about-us" />
      </Helmet>
      <div className="about-us">
        <Container>
          <p>
            <span style={{ fontSize: "24px" }}>A</span>t TCF Accessories, we
            dedicate our services to providing mobile accessories and
            electronics and are committed to delivering high-quality products at
            affordable prices across Kenya.
          </p>
          <p>
            Operating from our central business location at the Magic Business
            Center, Mfangano Street, Nairobi CBD, we specialize in offering a
            wide range of phone products, including chargers, earphones, cases,
            batteries, screen protectors, and more. Additionally, we go beyond
            our way to provide other electronic products at budget-friendly
            prices as well.
          </p>
          <p>
            Our mission is to ensure that our clients throughout the country
            have access to reliable, cost-effective solutions that meet their
            everyday needs. With a focus on quality and value, TCF Accessories
            strives to be your trusted partner in keeping you connected and
            equipped.
          </p>
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
