import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import navIcon2 from "../../assets/images/nav-icon2.svg";
import navIcon3 from "../../assets/images/nav-icon3.svg";
import navIcon4 from "../../assets/images/whatsapp-svg.svg";
import tiktok from "../../assets/images/tiktok-outline-svg.svg";
import twitter from "../../assets/images/icons8-x.svg";

// import logo from "../../logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col sm={12} className="map">
            <div style={{ width: "100%" }}>
              <iframe
                title="TCF Store"
                width="100%"
                height="300"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=TCF,%20Magic%20business%20centre,%20Mfangano%20Ln,%20Nairobi+(TCF%20Store)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.gps.ie/">gps devices</a>
              </iframe>
            </div>
          </Col>
        </Row>
        {/* <Row style={{ justifyContent: "space-between" }}>
          <Col sm={4} className="contact-details">
            <h3>Location</h3>
            <hr />
            <p>TCF Store</p>
            <p>Magic business centre, Mfangano Ln, Nairobi</p>

            <h5>Opening</h5>
            <p>Mon to Sat 9:00am to 6:30p.m</p>

            <hr />
            <p>
              <b>Email</b> Us at :{" "}
              <a href="mailto:support@tcfstore.com">support@tcfstore.com</a>
            </p>

            <p>
              <b>Contact</b> Us at : 0715 862 727, 0727 227 647
            </p>
            <hr />
          </Col>
          <Col sm={3} className="other-links">
            <h5>Pages</h5>
            <Link>About Us</Link>
            <Link>Privacy Policy</Link>
            <Link>Return Policy</Link>
            <Link>Warrant Policy</Link>
          </Col>
        </Row>
        <Row className="align-item-center">
          <Col sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="/">
                <img src={navIcon4} alt="nav1" />
              </a>
              <a href="/">
                <img src={navIcon2} alt="nav2" />
              </a>
              <a href="/">
                <img src={navIcon3} alt="nav3" />
              </a>
            </div>
            <p>CopyRight {year}. All rights reserved by MilesCorp.</p>
          </Col>
        </Row> */}
        <Row className="top_part">
          <Col sm={3}>
            {/* <h5>Information</h5>

            <ul>
              <li>
                <Link>About Us</Link>
              </li>
            </ul> */}
            <h5>Business Hours</h5>
            <ul>
              <li>
                Mon - Sat <br /> 6:00 AM to 8:00 PM
              </li>
            </ul>

            <br />

            <h5>Contact Us</h5>
            <p>
              <a href="mailto:support@tcfstore.com">support@tcfstore.com</a>
            </p>
            <p>(0715) 862-727</p>
            <p>(0727) 227-647</p>
            <br />
            <h5>Location</h5>
            <p>Magic Business Centre, Mfangano Ln, Nairobi</p>
          </Col>
          <Col sm={3}>
            <h5>Top Categories</h5>

            <ul>
              <li>
                <Link to={"/Electronics/"}>Electronics</Link>
              </li>
              <li>
                <Link to={"/Ear Buds & Ear Pods/"}>Ear Buds & Ear Pods</Link>
              </li>
              <li>
                <Link to={"/Phones & Tablets/"}>Phones & Tablets</Link>
              </li>
              <li>
                <Link to={"/Chargers/"}>Chargers</Link>
              </li>
            </ul>
          </Col>
          <Col sm={3}>
            <h5>My Account</h5>

            <ul>
              <li>
                <Link>My Account</Link>
              </li>
              <li>
                <Link>My Orders</Link>
              </li>
            </ul>
          </Col>
          <Col sm={3} className="info-footer">
            <h5>Information</h5>

            <Link to={"/about-us"}>About Us</Link>

            <ul className="social-icon">
              <li>
                <a href="https://wa.me/254715862727/">
                  <img src={navIcon4} alt="nav1" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/TCFAccessories/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={navIcon2} alt="nav2" />
                </a>
              </li>

              <li>
                <a
                  href="https://www.instagram.com/tcfaccessories/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={navIcon3} alt="nav3" />
                </a>
              </li>

              <li>
                <a
                  href="https://x.com/tcf_accessories"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="nav3" />
                </a>
              </li>

              <li>
                <a
                  href="https://www.tiktok.com/@tcfaccessories"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={tiktok} alt="nav3" />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="bottom_part">
          <div className="col-sm-12">
            <p>
              TCF &copy; {year}{" "}
              <span style={{ borderLeft: "1px solid", margin: "0 5px" }}></span>{" "}
              Powered by &copy;
              <span>
                <a href="https://www.milescorp.org/">MilesCorp.</a>
              </span>
            </p>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
