import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PriceRange from "../../Components/Body/PriceRange";
import SearchSelectList from "../../Components/Body/SearchSelectList";
import PageProductsListing from "../../Components/Body/PageProductsListing";
import { FilterContext } from "../../Context/FilterContext";
import { DataContext } from "../../Context/DataContext";
import { List, X } from "react-bootstrap-icons";
import { Helmet } from "react-helmet-async";

const CategoryPage = ({ categoryName }) => {
  const location = useLocation();

  const {
    minPrice,
    maxPrice,
    brands,
    colors,
    setColors,
    setBrands,
    setMinPrice,
    setMaxPrice,
  } = useContext(FilterContext);

  let stillComputing = useRef(true);

  const { categoriesList, colorsList, brandsList } = useContext(DataContext);

  const urlParams = new URLSearchParams(location.search);

  const search = urlParams.get("q");

  // check if the categories in url exist
  const [checkedCategLevels, setcheckedCategLevels] = useState([]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    function getCategs(params) {
      if (categoriesList.length >= 1) {
        let p = [];

        for (const i in params) {
          const categ = categoriesList.find((c) => {
            return c.name.toLowerCase() === params[i].toLowerCase();
          });
          if (categ) {
            p.push(categ);
          } else {
            // if categ is undefined, i.e, not found, display page not found
          }
        }
        setcheckedCategLevels(p);
        stillComputing.current = false;
      }
    }

    getCategs(categoryName.split("-"));
  }, [categoryName, categoriesList]);

  return (
    <>
      <Helmet>
        <title>TCF Store | {categoryName}</title>
        <meta
          name="description"
          content={`Browse through TCF Accessories' ${categoryName} Catalog.`}
        />
        <link rel="canonical" href={`/${categoryName}`} />
      </Helmet>
      <div className="categoryPage">
        <Container className="customContainer">
          <div className="followLinks">
            <Link to={"/"}>Home </Link>
            {checkedCategLevels.map((c, index) => {
              return (
                <span key={index}>
                  &gt; <Link to={`/${c.name}/`}>{c.name} </Link>
                </span>
              );
            })}
          </div>
          <div className="toggleShowFilter">
            <Button onClick={() => setShow(!show)}>
              <List /> Filter
            </Button>
          </div>
          <Row className="customRow">
            <Col
              sm={4}
              className={
                show
                  ? "pageFilters categoryPageFilters showFilter"
                  : "pageFilters categoryPageFilters"
              }
            >
              <X
                size={30}
                className="closeFilter"
                onClick={() => setShow(false)}
              />
              <h3>CATEGORY</h3>
              <div className="categLvlLinks">
                {checkedCategLevels.map((c, index) => {
                  return (
                    <Link
                      to={`/${c.name}/`}
                      key={index}
                      className="topLvlCategLinks"
                      style={{
                        color:
                          index === checkedCategLevels.length - 1
                            ? "#232323c7"
                            : "gray",
                        fontWeight:
                          index === checkedCategLevels.length - 1
                            ? "500"
                            : "400",
                      }}
                    >
                      {c.name}
                    </Link>
                  );
                })}
                {checkedCategLevels[checkedCategLevels.length - 1] &&
                  checkedCategLevels[
                    checkedCategLevels.length - 1
                  ].sub_categories.map((sub_categ, index) => {
                    return (
                      <Link
                        to={`/${categoryName}-${sub_categ.name}/`}
                        key={index}
                        className="subLvlCategLinks"
                      >
                        {sub_categ.name}
                      </Link>
                    );
                  })}
              </div>

              <hr />
              <h5>PRICE(KSH)</h5>
              <PriceRange
                minPrice={minPrice}
                maxPrice={maxPrice}
                setUpMax={setMaxPrice}
                setUpMin={setMinPrice}
              />
              <hr></hr>
              <h5>BRAND</h5>
              <SearchSelectList
                items={brandsList}
                keyName="name"
                setSelected={setBrands}
                selectedItems={brands}
              />
              <hr></hr>
              <h5>COLOR</h5>
              <SearchSelectList
                items={colorsList}
                keyName={"value"}
                setSelected={setColors}
                selectedItems={colors}
              />
            </Col>
            <Col sm={8} className="pageProductsListing">
              <PageProductsListing
                name={
                  checkedCategLevels.length >= 1
                    ? checkedCategLevels[checkedCategLevels.length - 1]["name"]
                    : ""
                }
                categList={checkedCategLevels}
                brand={brands}
                color={colors}
                minPrice={minPrice}
                maxPrice={maxPrice}
                search={search}
                stillComputing={stillComputing.current}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CategoryPage;
